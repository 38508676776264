<template>
  <div
    class="rounded-lg text-center"
  >
    <div class="py-5 text-center ">
      <div class="mb-5">
        <b>Only United States Citizens, U.S. Nationals, or U.S. Permanent Residents may apply to the NSF I-PERF
          program.</b>
      </div>
      <span class="text-light">Proof of citizenship status is required.</span>
    </div>
    <div class="m-5 flex flex-col  items-center  gap-10">
      <div class="font-mono text-xl">
        Are you a U.S. Citizen / U.S. Permanent Resident ?
      </div>

      <button
        class="btn btn-success btn-lg btn-wide p-5 text-white"
        @click="$emit('yes')"
      >
        Yes, I am
      </button>

      <a
        class="link"
        @click="$emit('no')"
      >I am not</a>
    </div>
  </div>
</template>
<script>

</script>