<template>
  <div class="w-96">
    <HeaderLabel
      :show-back="showBack"
      name="New Account"
      @back="$emit('update:view', 1)"
    />

    <CitizenCheck
      v-if="viewId === 1"
      @no="$emit('update:view', 1)"
      @yes="viewId=2"
    />
    <div
      v-if="viewId === 3"
      class="flex flex-col gap-10 text-center"
    >
      <div class="font-serif text-3xl text-success">
        Successfully registered
      </div>
      <div class="text-neutral">
        Please, check you email for instructions to verify email and login.
      </div>
      <div class="flex items-center justify-center gap-3">
        <router-link
          class="btn btn-xs"
          to="/home"
        >
          Home
        </router-link>
        <a
          class="btn btn-xs"
          href="/app"
        >Login</a>
      </div>
    </div>
    <Error :message="errorMessage" />

    <Vueform
      v-if="viewId === 2"
      ref="form$"
      :display-errors="false"
      :endpoint="formSubmit"
    >
      <TextElement
        label="First Name"
        name="first_name"
        rules="required"
      />
      <TextElement
        label="Middle Name"
        name="middle_name"
      />
      <TextElement
        label="Last Name"
        name="last_name"
        rules="required"
      />
      <TextElement
        label="Email"
        name="email"
        rules="required|email"
      />

      <GroupElement
        class=""
        name="password_block"
      >
        <TextElement
          input-type="password"
          label="Password"
          name="password"
          rules="required|confirmed"
        />
        <TextElement
          input-type="password"
          label="Password Confirmation"
          name="password_confirmation"
          rules="required"
        />
      </GroupElement>

      <CaptchaElement
        align="center"
        class="my-3"
        name="captcha"
      />

      <StaticElement
        class="my-5"
        name="btn"
      >
        <ButtonElement
          :full="true"
          :submits="true"

          button-label="Create"
          name="register"
        />
      </StaticElement>
    </Vueform>
  </div>
</template>
<script setup>
import {ref} from "vue";
import HeaderLabel from "~/pages/auth/_header_label.vue";
import CitizenCheck from './_citizen_check.vue'
import Error from "~/components/ui/error.vue";

const viewId = ref(1)
const errorMessage = ref('')
const props = defineProps( {showBack: {type: Boolean, default: true}})
async function formSubmit(FormData, form$) {
  form$.submitting = true
  errorMessage.value = ''
  try{
    const data = {user: {...form$.requestData, type: 'User::Applicant'}}
    const response  = await form$.$vueform.services.axios.post( '/user/signup', data)
    if (response.data.status === 'failed') {
      errorMessage.value = response.data.error
    } else {
      viewId.value = 3
    }
  } catch(error){
    console.log(error)
    errorMessage.value = error.response.data.error
  }finally{
    form$.submitting = false
  }
}
</script>